$(function() {
    let urlhashPart = window.location.hash;
    if (urlhashPart == "#notify") {
        $("#notifications-dropdown").hide();
    }

    //dropdown code
    let notificationDropDown = $(
        "#notifications-dropdown .ui-dropdown-menu-wrap"
    );
    let allNotifications = $("[id^='notification-message-']");
    let totalNotificationsCountElement = $("#total_notifications_count");
    let totalNotificationsCount = totalNotificationsCountElement.data(
        "total-notifications"
    );
    let csrfToken = $("meta[name='csrf-token']").attr("content");
    allNotifications.each(function(index, element) {
        let notification_id = $(this).data("id");
        let statusUrl = $(this).data("status-update-url");

        $(this).on("click", function(e) {
            e.preventDefault();
            e.stopPropagation();

            $.ajax({
                url: statusUrl,
                type: "POST",
                data: { notification_id, isRead: 1 },
                dataType: "json",
                headers: { "X-CSRF-TOKEN": csrfToken },
                success: notificationStatusChangeSuccessHandler.bind(
                    $(this),
                    this
                ),
                error: notificationStatusChangeErrorHandler.bind(this)
            });
        });

        let notificationStatusChangeSuccessHandler = function(_this, result) {
            let subject_id = $(_this).data("subject-id"),
                notify_type = $(_this).data("notify-type");

            if (totalNotificationsCount > 0) {
                totalNotificationsCount -= 1;
                totalNotificationsCountElement.text(totalNotificationsCount);
                $(this).hide();
            }
            if (totalNotificationsCount == 0) {
                totalNotificationsCountElement.hide();
                $(this).hide();
                notificationDropDown.hide();
            }
            if (
                $.inArray(notify_type, [
                    "SPEAKING_TEST_RESULT",
                    "WRITING_TEST_RESULT"
                ]) !== -1
            ) {
                window.location.href =
                    $("#url").val() +
                    "/notifications/view-test-result/" +
                    subject_id;
            } else if (notify_type == "COURSE_PURCHASE") {
                window.location.href =
                    $("#url").val() + "/course/" + result.data.slug + "/view";
            }
        };
        let notificationStatusChangeErrorHandler = function(
            xhrObject,
            status,
            error
        ) {
            //failure
        };
    });

    //profile notifications section
    let notificationListsElementList = $("[id^='list-notification-message-']");
    attachNotificationUpdateStatusChangeHandler(notificationListsElementList);

    function attachNotificationUpdateStatusChangeHandler(
        notificationListsElementList
    ) {
        notificationListsElementList.each(function(index, element) {
            let notificationStatusUpdateUrl = $(this).data(
                "notification-update-url"
            );
            let redirectUrl = $(this).data("redirect-url");
            let notification_id = $(this).data("notify-id");
            let isRead = 1;

            $(this).on("click", function(e) {
                e.preventDefault();
                e.stopPropagation();
                $.ajax({
                    url: notificationStatusUpdateUrl,
                    type: "POST",
                    data: { notification_id, isRead },
                    dataType: "json",
                    headers: { "X-CSRF-TOKEN": csrfToken },
                    success: function(result) {
                        let currentNotificationsCount = parseInt(
                            totalNotificationsCountElement.text()
                        );
                        currentNotificationsCount -= 1;
                        if (totalNotificationsCount == 0) {
                            totalNotificationsCountElement.text("");
                        } else {
                            totalNotificationsCountElement.text(
                                currentNotificationsCount
                            );
                        }
                        if (redirectUrl) {
                            location.href = redirectUrl;
                        }
                    },
                    error: function(jqXHR, textStatus, error) {
                        console.log(error);
                    }
                });
            });
        });
    }
    let loadMoreNotificationsButton = $("#load-more-notifications-button");
    let notificationsListWrapper = $("#notification-list-wrapper");
    let newNotificationsFeedbackElement = $("#new-notifications-feedback");
    loadMoreNotificationsButton.on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        let loadMoreNotificationsUrl = $(this).data(
            "load-more-notifications-url"
        );
        newNotificationsFeedbackElement.addClass("d-none").text("");
        let currentPage = $(this).data("current-page");
        currentPage = parseInt(currentPage) + 1;
        $.ajax({
            type: "GET",
            url: loadMoreNotificationsUrl + "?page=" + currentPage,
            dataType: "json",
            headers: { "X-CSRF-TOKEN": csrfToken },
            success: function(result) {
                $("#load-more-notifications-button").data(
                    "current-page",
                    currentPage
                );
                let notificationsMarkups = result.data;
                notificationsMarkups.forEach(function(markup) {
                    notificationsListWrapper.append(markup);
                });
                let notificationListsElementList = $(
                    "[id^='list-notification-message-']"
                );

                attachNotificationUpdateStatusChangeHandler(
                    notificationListsElementList
                );
            },
            error: function(jqXHR, textStatus, error) {
                let response = jqXHR.responseText;
                if (response) {
                    response = JSON.parse(response);
                    let generalMessage = response.message;
                    newNotificationsFeedbackElement
                        .removeClass("d-none")
                        .text(generalMessage);
                } else {
                    newNotificationsFeedbackElement
                        .removeClass("d-none")
                        .text("Please check your network connection");
                }
            }
        });
    });

    //notifiactions update section
    let notificationReadAllUrl = $("#notifications-dropdown").data("read-all-notifications-url");
    $("#myTab").on("shown.bs.tab", function(e) {
        if (e.target.id == "notify-tab") {
            updateAllnotificationsAsRead();
        }
    });

    let currentLocation = location.href;
    let locationParts = currentLocation.split("/");
    let lastSegment = locationParts[locationParts.length - 1];
    if (lastSegment == "profile#notify") {
        updateAllnotificationsAsRead();
    }

    //mark all notifications as read
    function updateAllnotificationsAsRead() {
        $.ajax({
            type: "GET",
            url: notificationReadAllUrl,
            dataType: "json",
            headers: { "X-CSRF-TOKEN": csrfToken },
            success: function(result) {
                console.log("All notifications marked as read");
                $('#total_notifications_count').addClass('hide');
            },
            error: function(err) {
                console.log("Something went wrong when notifications update");
            }
        });
    }

    // mark notifications as read
    let notificationIcon = $("#notifications-dropdown");
    notificationIcon.on("show.bs.dropdown", function(e) {
        let notificationReadAllUrl = $(this).data("read-all-notifications-url");
        updateAllnotificationsAsRead();
    });

    //clear all notifications
    let clearAllbtn = $("#notify_clear_all_btn");
    clearAllbtn.on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        let clearAllUrl = $(this).data("clear-all-notify-url");

        $.ajax({
            type: "GET",
            url: clearAllUrl,
            dataType: "json",
            headers: { "X-CSRF-TOKEN": csrfToken },
            success: function(result) {
                console.log("All notifications cleared");
                location.href = "profile#notify";
            },
            error: function(err) {
                console.log("Something went wrong when clearing notifications");
            }
        });
    });
});
